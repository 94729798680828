<template>
  <section
    class="retargeted"
    v-if="retargetedProducts && retargetedProducts.length > 0"
  >
    <div class="container">
      <div class="row">
        <div class="col-12">
          <span class="section-heading font-weight-normal text-center d-block"
            >Recent bekeken</span
          >
        </div>
      </div>
      <ClientOnly>
        <Carousel
          :autoplay="true"
          :loop="true"
          :navigationEnabled="true"
          :perPage="noOfSlidePerPage"
          :scrollPerPage="false"
          :paginationEnabled="false"
          navigationPrevLabel="<i class='lnr lnr-chevron-left'></i>"
          navigationNextLabel="<i class='lnr lnr-chevron-right'></i>"
        >
          <Slide
            class="filtered-products"
            v-for="product of retargetedProducts"
            :key="product.id"
          >
            <ProductCard :product="product" :optionHeight="getOptionHeight" />
          </Slide>
        </Carousel>
      </ClientOnly>
    </div>
  </section>
</template>

<script>
import ProductCard from "@/base/core/components/ProductCard";
import ClientOnly from "vue-client-only";
import { isServer } from "@storefront/core/helpers";

export default {
  name: "Retargeted",
  props: {
    page: {
      type: String,
      required: false,
      default: "home",
    },
  },
  components: {
    Carousel: () =>
      !isServer ? import("vue-carousel").then((m) => m.Carousel) : null,
    Slide: () =>
      !isServer ? import("vue-carousel").then((m) => m.Slide) : null,

    ClientOnly,
    ProductCard,
  },
  mounted() {
    if (!isServer) {
      this.setNoOfSlidePerPage();
      window.addEventListener("resize", this.setNoOfSlidePerPage);
      window.addEventListener("load", this.setNoOfSlidePerPage);
    }
  },
  destroyed() {
    if (!isServer) {
      window.removeEventListener("resize", this.setNoOfSlidePerPage);
      window.removeEventListener("load", this.setNoOfSlidePerPage);
    }
  },
  data: () => ({
    noOfSlidePerPage: 3,
  }),
  methods: {
    setNoOfSlidePerPage() {
      if (window.innerWidth < 768) {
        this.noOfSlidePerPage = 1;
      } else {
        this.noOfSlidePerPage = 3;
      }
    },
  },
  computed: {
    getOptionHeight() {
      this.retargetedProducts.forEach((p) => {
        if (p.configurable_options) {
          p.configurable_options.find((co) => {
            if (co.values) {
              if (co.values.length > this.maxCount) {
                this.maxCount = co.values.length;
              }
            }
          });
        }
      });
      let returnValue = Math.floor(this.maxCount / 4, 0) + 1;
      return returnValue;
    },
    retargetedProducts() {
      return this.$store.getters["home/getRetargetedProducts"];
    },
  },
};
</script>
