<template>
  <div
    class="product-card--holder"
    v-match-heights="{ el: ['.available-sizes-list'] }"
  >
    <b-link v-if="isLoggedIn" class="add-to-wishlist" @click="toggleWishItem">
      <div
        class="heart"
        v-if="$store.getters['user/isProductInWishlist'](product.sku)"
      >
        <img src="@/base/assets/heart.png" alt="heart" />
      </div>
      <i class="lnr lnr-heart" v-else></i>
    </b-link>
    <b-link
      :to="`/${product.url_key}`"
      class="product-card"
      @click="trackClick"
    >
      <div>
        <img
          class="product-card--img-top"
          :src="product.image.medium"
          width="240"
          height="220"
          v-if="product.image && product.image.medium"
          @error="setDefaultPlaceholder"
        />
        <img
          class="product-card--img-top"
          width="240"
          height="220"
          v-else
          src="@/base/assets/default-placeholder-image.jpg"
        />
        <div class="actionbuttons">
          <span class="sale-txt" v-if="onSale == true">Sale</span>
          <span class="new-txt" v-if="onNew == true">Nieuw</span>
          <span
            class="empty-txt"
            v-if="onNew == false && onSale == false"
          ></span>
        </div>
      </div>

      <div class="product-card--product-name">{{ product.name }}</div>
      <ul
        v-for="attr of product.configurable_options"
        :key="attr.id"
        class="available-sizes-list d-none d-md-flex flex-wrap justify-content-center list-unstyled p-0"
        ref="productOptions"
      >
        <li v-for="opt of attr.values" :key="opt.value_index">
          {{ opt.label }}
        </li>
      </ul>
      <ul
        v-if="!product.configurable_options"
        class="available-sizes-list d-none d-md-flex flex-wrap justify-content-center list-unstyled p-0"
      ></ul>
      <div class="bottombox">
        <Rating
          :stars="product.rating_summary"
          :noOfRatings="product.review_count"
          v-if="product.review_count > 0"
        />
        <span
          v-html="getProductPriceRange.text"
          class="d-block product-card--price"
        >
        </span>

        <span
          class="d-block product-card--sale-comment"
          v-if="product.price_range.minimum_price.discount.percent_off > 0"
          >{{ $t("discount") }}
          {{
            product.price_range.minimum_price.discount.percent_off.toFixed(0)
          }}%</span
        >
        <span
          class="d-block product-card--sale-comment"
          style="visibility: hidden"
          v-if="product.price_range.minimum_price.discount.percent_off == 0"
          >{{ $t("discount") }}
          {{
            product.price_range.minimum_price.discount.percent_off.toFixed(0)
          }}%</span
        >
      </div>
    </b-link>
  </div>
</template>

<script>
import Rating from "@/base/core/components/Rating";
import { isServer } from "@storefront/core/helpers";
import config from "@config";

export default {
  name: "ProductCard",
  props: {
    product: {
      type: Object,
      require: true,
    },
    optionHeight: {
      type: Number,
      require: true,
    },
  },
  data() {
    return {};
  },
  components: {
    Rating,
  },
  computed: {
    onSale() {
      return this.product.sale;
    },
    onNew() {
      return this.product.new;
    },
    isLoggedIn() {
      return this.$store.getters["user/getIsLoggedIn"];
    },
    getBottomMargin() {
      if (
        (this.product.sale == 1 || this.product.new == 1) &&
        (this.product.price_range.minimum_price.discount.percent_off > 0 ||
          this.product.price_range.maximum_price.discount.percent_off > 0)
      ) {
        return "m20";
      } else {
        return "";
      }
    },
    getProductPriceRange() {
      if (this.product.__typename == "SimpleProduct") {
        return {
          text:
            '<span class="currentPrice">' +
            this.formatCurrency(
              this.product.price_range.maximum_price.final_price.value
            ) +
            "</span>",
          discountType: 1,
        };
      } else if (this.product.__typename == "BundleProduct") {
        let lowestPrice =
          this.product.price_range.minimum_price.final_price.value;
        let highestPrice =
          this.product.price_range.maximum_price.final_price.value;
        if (lowestPrice == highestPrice) {
          return {
            text:
              '<span class="strike">' +
              this.formatCurrency(
                this.product.price_range.maximum_price.regular_price.value
              ) +
              '</span> <span class="currentPrice">' +
              this.formatCurrency(lowestPrice) +
              "</span>",
            discountType: 1,
          };
        } else {
          return {
            text:
              '<span class="currentPrice">' +
              this.formatCurrency(lowestPrice) +
              " - " +
              this.formatCurrency(highestPrice) +
              "</span>",
            discountType: 2,
          };
        }
      } else {
        if (this.productConfig) {
          let lowestPrice =
            this.product.price_range.maximum_price.regular_price.value;
          let highestPrice =
            this.product.price_range.minimum_price.final_price.value;
          if (this.productConfig.variants) {
            this.productConfig.variants.forEach((v) => {
              if (
                v.product.price_range.minimum_price.final_price.value <
                lowestPrice
              ) {
                lowestPrice =
                  v.product.price_range.minimum_price.final_price.value;
              }
              if (
                v.product.price_range.maximum_price.final_price.value >
                highestPrice
              ) {
                highestPrice =
                  v.product.price_range.maximum_price.final_price.value;
              }
            });
          }
          if (
            lowestPrice == highestPrice &&
            lowestPrice !==
              this.product.price_range.maximum_price.regular_price.value
          ) {
            return {
              text:
                '<span class="strike">' +
                this.formatCurrency(
                  this.product.price_range.maximum_price.regular_price.value
                ) +
                '</span> <span class="currentPrice">' +
                this.formatCurrency(lowestPrice) +
                "</span>",
              discountType: 1,
            };
          } else if (lowestPrice !== highestPrice) {
            return {
              text:
                '<span class="currentPrice">' +
                this.formatCurrency(lowestPrice) +
                " / " +
                this.formatCurrency(highestPrice) +
                "</span>",
              discountType: 2,
            };
          } else {
            return {
              text:
                '<span class="currentPrice">' +
                this.formatCurrency(lowestPrice) +
                "</span>",
              discountType: 1,
            };
          }
        } else {
          return 1;
        }
      }
    },
    productConfig() {
      return { variants: this.product.variants };
    },
    awSource() {
      return this.$store.getters["home/getAwSource"];
    },
  },
  methods: {
    toggleWishItem() {
      if (this.$store.getters["user/getIsLoggedIn"] == true) {
        if (
          this.$store.getters["user/isProductInWishlist"](this.product.sku) ==
          true
        ) {
          this.$store.dispatch("user/setProductWishlistStatus", {
            sku: this.product.sku,
            parentSku: null,
          });
        } else {
          if (this.product.__typename == "SimpleProduct") {
            this.$store.dispatch("user/setProductWishlistStatus", {
              sku: this.product.sku,
              parentSku: null,
            });
          } else {
            const msg = {
              type: "danger",
              title: this.$t("wishlist error"),
              text: this.$t("wishlist_select_options"),
            };
            this.$store.dispatch("messages/sendMessage", { message: msg });
            this.$router.push("/" + this.product.url_key);
          }
        }
      } else {
        const msg = {
          type: "danger",
          title: this.$t("wishlist error"),
          text: this.$t("wishlist_logged_in"),
        };
        this.$store.dispatch("messages/sendMessage", { message: msg });
      }
    },
    formatCurrency(amount) {
      var formatter = new Intl.NumberFormat("nl-NL", {
        style: "currency",
        currency: "EUR",
      });
      return formatter.format(amount);
    },
    setDefaultPlaceholder() {
      this.product.image.medium = null;
    },
    trackClick() {
      if (!isServer) {
        if (config.helloRetail && config.helloRetail.enabled) {
          const aw_source = JSON.parse(localStorage.getItem("aw_source")) ?? [];
          const found = aw_source.find(({ sku }) => sku == this.product.sku);
          var hrq = window.hrq || [];
          hrq.push(["trackClick", found?.aw_source]);
        }
      }
      this.$bvModal.hide("ProductCardModal");
      return true;
    },
  },
};
</script>
