<template>
  <div class="ratings d-none d-md-flex align-items-center">
    <ul class="ratings-star">
      <li
        v-for="(isFill, idx) of rateStars"
        :key="idx"
        :class="isFill ? 'text-yellow' : 'text-gray'"
      >
        <i class="lnr lnr-star"></i>
      </li>
    </ul>
    <span class="no-of-ratings">({{ noOfRatings }})</span>
  </div>
</template>

<script>
export default {
  name: "Rating",
  props: {
    stars: {
      type: Number,
    },
    noOfRatings: {
      type: Number,
    },
  },
  computed: {
    rateStars() {
      return new Array(5).fill(false).map((_, i) => i < this.stars / 10 / 2);
    },
  },
  data() {
    return {};
  },
};
</script>


